/*Mouse drag rotation*/
#radio-knob-controller {
	width: 300px;
	position: absolute;
	top: -5%;
	left: 50%;
	-webkit-transform: translate(-50%, 5%);
	transform: translate(-50%, 5%);
	z-index:2;
}
#controller {
	position:relative;
	z-index:2;
	display: block;
	height: 300px;
	width: 300px;
	border-radius: 50%;
	/*background-color: #cd9552;*/
}
#controllerCont{
	cursor: -webkit-grab;
	cursor: -moz-grab;
	cursor: -o-grab;
	cursor: grab;
	position:absolute;
	top:0;
	z-index:2;
	display: block;
	height: 300px;
	width: 300px;
	border-radius: 50%;
}
#controllerCont:active {
	cursor: -webkit-grabbing;
	cursor: -moz-grabbing;
	cursor: -o-grabbing;
	cursor: grabbing;
}