/*Radio*/
#radio {
	/*background-color:blue;*/
	width: 500px;
	/*width: 80%;
	max-width: 1200px;
	min-width: 900px;*/
	height: 510px;
	/*height: 80%;
	min-height:510px;
	max-height:580px;*/
	position: absolute;
	top: 35%;
	left: 50%;
	-webkit-transform: translate(-50%, -35%);
	transform: translate(-50%, -35%);
	z-index: 10;
	/*opacity: 0;*/
}

/*Media Queries*/
@media screen and (max-height: 612px) {
	#radio {
		width: 500px;
		height: 510px;
		position: absolute;
		top: 0%;
		left: 50%;
		-webkit-transform: translate(-50%, -0%);
		transform: translate(-50%, -0%);
		/*Jump margin FIX*/
		margin-top:35px;
		z-index: 10;
		/*opacity: 0;*/
	}
}