/*QR code modal*/
#qr-code-modal{
}

/*Overlay*/
#overlayQrCodeModal {
	background-color: #000;
	position: fixed;
	width: 100%; 
	height: 100%;
	z-index: 996;
	/*opacity: 0.85;*/
	opacity: 0;
	display: none;
}

/*Wrap*/
#wrapQrCode {
	position: fixed;
	width: 100%; 
	height: 100%;
	overflow: hidden;
	z-index: 997;
	opacity: 0;
	display: none;
}

/*Content*/
#qrCodeContent {
	position: absolute;
	width: auto;
	height: 400px;
	top: 42%;
	left: 50%;
	-webkit-transform: translate(-50%, -42%);
	transform: translate(-50%, -42%);
}

/*Media Queries*/
@media screen and (max-height: 400px) {
	#qrCodeContent {
		top: 0%;
		left: 50%;
		-webkit-transform: translate(-50%, -0%);
		transform: translate(-50%, -0%);
	}
}

/*QR code instructions*/
#qrcode-instructions{
	width:308px;
	height: auto;
	position: absolute;
	top: -2%;
	left: 50%;
	-webkit-transform: translate(-50%, 2%);
	transform: translate(-50%, 2%);
}

#qrcode-instructions h1{
	font-family: "futura-pt",sans-serif;
	/*font-family: Futura, "Trebuchet MS", Arial, sans-serif;*/
	font-weight: 400;
	font-size:30px;
	text-align: center;
	line-height: 100%;
	letter-spacing: normal;
	color: #FFF;
}

#qrcode-instructions p{
	font-family: "futura-pt",sans-serif;
	/*font-family: Futura, "Trebuchet MS", Arial, sans-serif;*/
	font-weight: 400;
	font-size:22px;
	text-align: center;
	line-height: 105%;
	letter-spacing: normal;
	color: #FFF;
	margin-top: -5px;
}

/*QR code*/
#qrcode{
	/*width:250px;
	height: auto;*/
	display: block;
	position: absolute;
	bottom: 0%;
	left: 50%;
	-webkit-transform: translate(-50%, 0%);
	transform: translate(-50%, 0%);
	border:10px solid white;
	/*border-radius: 10px;*/
}

/*Close button*/
#closeQrCodeModal{
	width: 100px;
	height: auto;
	position: fixed;
	top: 2px;
	right: 2px;
	cursor: pointer;
	z-index: 1001;
}
#closeQrCodeModal img{
	width: 100%;
	height: auto;
	display: block;
	position: relative;
	padding: 0px;
	margin: 0 auto !important;
}

