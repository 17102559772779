/*Spotlight icon*/
#spotlightIcon{
	width: auto;
	height: 58px;
	position: fixed;
	bottom: 2px;
	left: 2px;
	cursor: pointer;
	z-index: 999;
}
#spotlightIcon img{
	display: block;
	position: relative;
	width: auto;
	height: 100%;
	padding: 0px;
	margin: 0 auto !important;
}