/*Source: https://css-tricks.com/tabs-with-round-out-borders/*/
/*Source: https://stackoverflow.com/questions/5351634/css-side-by-side-divs-auto-equal-widths*/

#chart-tabs{
	display:block;
	width: 80%;
	max-width: 900px; 
	min-width: 600px;
	height: 60px;
	margin-left:auto;
	margin-right:auto;
	z-index: 13; /*Above chart-content*/
}
/*.safari #chart-tabs{
	padding-left:7px;
}*/
#chart-tabs h1 {
	font-family: "futura-pt",sans-serif;
	font-size: 1.08em;
	font-weight: 700;
	line-height: 1.25;
	text-align: center;
	text-transform: uppercase;
}
#operatingInstructionsTab, #stationChartTab{
	cursor: pointer;
}

.tabs {
	display: flex;
	list-style: none; 
	width:100%;
	height:60px;
}
.tabs li { 
	position: relative; 
	flex-basis: 100%;
	padding: 8px 40px;
	text-decoration: none;

	/* Default colors */ 
	color: black;
	background: #ddc385; 

	/* Only round the top corners */
	-webkit-border-top-left-radius: 15px;
	-webkit-border-top-right-radius: 15px;
	-moz-border-radius-topleft: 15px;
	-moz-border-radius-topright: 15px;
	border-top-left-radius: 15px;
	border-top-right-radius: 15px;
}

/*Position corner tab images */
.tabs img#cornerLeftTab {
	width: 18px;
	height: auto;
	float: right;
	margin-right: -58px;
	margin-top: -10px;
}
.tabs img#cornerRightTab {
	width: 18px;
	height: auto;
	float: left;
	margin-left: -58px;
	margin-top: -10px;
}

.tabs .active { 
	/* Colors when tab is active */
	background: white; 
	color: black;
	/* Highest, active tab is on top */
	z-index: 3;
}

/*Inset shadow*/ /*mod*/
.tabs .left {
	box-shadow: inset 0px -20px 40px -30px rgba(0,0,0,1);
}
.tabs .right {
	box-shadow: inset 0px -20px 40px -30px rgba(0,0,0,1);
}

.tabs .active.left {
	position: relative;
	box-shadow: none;
}
.tabs .active.right {
	position: relative;
	box-shadow: none;
}

.tabs li:before, .tabs li:after, 
.tabs li h1:before, .tabs li h1:after {
	position: absolute;
	bottom: 0;
}

/*For 2 tabs*/
/* Only the first, last, and active
tabs need pseudo elements at all */
.tabs li:last-child:before,   .tabs li:last-child h1:before,
.tabs li:first-child:after, .tabs li:first-child h1:after{
	content: "";
}

.tabs .active:before, .tabs .active:after {
	background: white;
	z-index: 1;
}