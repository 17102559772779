#tunerBorder {
	width: 470px; /*mod*/
	height: 180px;
	position: absolute;
	top: 100%;
	left: 50%;
	transform: translate(-50%, -100%); /*mod*/
	overflow: hidden;
	background: linear-gradient(to top, rgba(249,249,249,1) 0%, rgba(200,200,200,1) 100%);
	/*background-color: white;*/
	border-radius: 28px;
	z-index: -11;
}
#tuner {
	width: 450px; /*mod*/
	height: 160px;
	position: absolute;
	top: 97%;
	left: 50%;
	transform: translate(-50%, -97%); /*mod*/
	overflow: hidden;
	background-color: black;
	border-radius: 20px;
}