/*Radio push buttons*/
/*Source: https://codepen.io/chriscoyier/pen/ctugI*/
#radio-push-buttons{
	width: 100%;
	height: auto;
	position: absolute;
	top: 55%;
	left: 50%;
	transform: translate(-50%, -55%);
	overflow: hidden;
}

.nav {
	list-style: none;
	text-align: center;
}
.nav img{
	margin-top: 13px;
	-webkit-tap-highlight-color: rgba(0,0,0,0); /*For mobile site*/
}
.nav li {
	position: relative;
	display: inline-block;
}
.nav li#info-button{
	margin-right: 120px;
}
.nav li#play-button{
	margin-left: 120px;
}
#info-button{
	opacity:0.3;
}
.nav a:link, .nav a:visited {
	display: block;
	text-decoration: none;
	background-color: #ffffff;
	background-image: -webkit-gradient(linear, left top, left bottom, from(#f7f7f7), to(#ffffff));
	background-image: -webkit-linear-gradient(top, #f7f7f7, #ffffff);
	background-image: -moz-linear-gradient(top, #f7f7f7, #ffffff);
	background-image: -ms-linear-gradient(top, #f7f7f7, #ffffff);
	background-image: -o-linear-gradient(top, #f7f7f7, #ffffff);
	color: #a7a7a7;
	margin: 36px;
	width: 50px;
	height: 50px;
	position: relative;
	text-align: center;
	line-height: 50px;
	border-radius: 50%;
	box-shadow: 0px 3px 8px #aaa, inset 0px 2px 3px #fff;
	border: solid 1px transparent;
	-webkit-tap-highlight-color: rgba(0,0,0,0); /*For mobile site*/
}
.nav a:before {
	content: "";
	display: block;
	background: #fff;
	border-top: 2px solid #ddd;
	position: absolute;
	top: -8px;
	left: -8px;
	bottom: -8px;
	right: -8px;
	z-index: -1;
	border-radius: 50%;
	box-shadow: inset 0px 8px 48px #ddd;
}
.nav a:active {
	box-shadow: none;
	border: solid 1px #a7a7a7;
}
.nav a:hover {
	text-decoration: none;
	color: #555;
	background: #ffffff;
}

/*Misc*/
.avoid-clicks {
	pointer-events: none;
}