#right-side-tab-message{
	width: auto;
	height: auto;
	position: absolute;
	top: 50%;
	right: 0%;
	-webkit-transform: translate(-0%, -50%);
	transform: translate(-0%, -50%);
	cursor: pointer;
	z-index: 100;
}
#right-side-tab-message div{
	width: auto;
	height: auto;
	padding:10px;
	background-color: white;
	border-radius: 10px 0px 0px 10px;
	/*Source: https://codepen.io/sdthornton/pen/wBZdXq*/
	box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
	/*transition: all 0.3s cubic-bezier(.25,.8,.25,1);*/
	z-index: 100;
}
#right-side-tab-message div p{
	margin-top:25px;
	font-family: "futura-pt",sans-serif;
	/*font-family: Futura, "Trebuchet MS", Arial, sans-serif;*/
	font-weight: 500;
	font-size:15px;
	line-height: normal;
	letter-spacing: normal;
	text-transform: uppercase;
	text-align: center;
	color: #000;
}

#redLight:before {
	content: " ";
	display: block;
	height: 14px;
	width: 14px;
	/*background: #c10d16;*/
	background: #F3413E;
	border-radius: 50%;
	/*box-shadow: 0 0 10px 3px #c10d16;*/
}
#redLight {
	opacity: .9;
	position: absolute;
	top: 30%;
	right: 35%;
	-webkit-transform: translate(-35%, -30%);
	transform: translate(-35%, -30%);
}