#overlayAjaxPresentationOpen {
	background-color: #000;
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0px;
	z-index: 1000;
	display: none;
}
#overlayAjaxPresentationClose {
	background-color: #000;
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0px;
	z-index: 1003;
	display: none;
}
#ajaxPresentationContainer {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1001;
	overflow:hidden;
	display: none;
}

/*Close button*/
#closeAjaxPresentationButton{
	width: 100px;
	height: auto;
	position: fixed;
	top: 2px;
	right: 2px;
	cursor: pointer;
	z-index: 1002;
	opacity: 0;
}
#closeAjaxPresentationButton img{
	width: 100%;
	height: auto;
	display: block;
	position: relative;
	padding: 0px;
	margin: 0 auto !important;
}

#iframeAjaxPresentationContainer{
	width: 100%;
	height: 100%;
}