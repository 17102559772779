/*Password protect form*/
#password-protect-form{
	position: fixed;
	width: 260px;
	height: 110px;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	z-index: 99999;
	display: none;
}
#password-protect-form p{
	font-family: "futura-pt",sans-serif;
	/*font-family: Futura, "Trebuchet MS", Arial, sans-serif;*/
	font-weight: 300;
	font-size: 20px;
	color: #000;
	text-align: left;
}
#password-protect-form input[type=password]{
	padding: 5px;
	margin-top: 10px;
	margin-bottom: 10px;
	margin-right: 3px;
	-webkit-user-select: text;
	-moz-user-select: text;
	-ms-user-select: text;
	user-select: text;
}
#password-protect-form button{
	padding: 6px;
	font-size: 15px;
}