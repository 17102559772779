/*Password protect form*/
#browser-error-message{
	width: 85%;
	max-width: 500px;
	height: auto;
	position: fixed;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	z-index: 99999;
	display: none;
	opacity: 0;
}
#browser-error-message p{
	font-family: "futura-pt",sans-serif;
	/*font-family: Futura, "Trebuchet MS", Arial, sans-serif;*/
	font-weight: 300;
	font-size: 20px;
	color: #000;
	text-align: center;
	padding-bottom: 5px;
}