/*Left side tab menu*/
#left-side-tab-menu{
	width: auto;
	height: auto;
	position: absolute;
	top: 50%;
	left: 0%;
	-webkit-transform: translate(-0%, -50%);
	transform: translate(-0%, -50%);
	z-index: 20;
	margin-left: -143px; /*Hide element initially*/ /*Element width + 3px for shadow*/
	/*margin-left: -147px;*/ /*Hide element initially*/ /*Sticky Stations Toggle Switch*/
}
#left-side-tab-menu ul{
	width: auto;
	height: auto;
	padding:15px;
	background-color: white;
	border-radius: 0px 10px 10px 0px;
	/*Source: https://codepen.io/sdthornton/pen/wBZdXq*/
	box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
	/*transition: all 0.3s cubic-bezier(.25,.8,.25,1);*/
	list-style: none;
	z-index: 1;
}
#left-side-tab-menu ul p{
	padding-top: 10px;
	padding-bottom: 10px;
	font-family: "futura-pt",sans-serif;
	/*font-family: Futura, "Trebuchet MS", Arial, sans-serif;*/
	font-weight: 500;
	font-size:15px;
	line-height: normal;
	letter-spacing: normal;
	text-transform: uppercase;
	text-align: left;
}
#left-side-tab-menu ul li{
	cursor: pointer;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	color: #000;
}
#left-side-tab-menu ul li:hover{
	cursor: pointer;
	color: #999;
}

/*Circle handle*/
#circleHandle {
	width: 45px;
	height: 90px;
	position: absolute;
	top: 50%;
	left: 0%;
	-webkit-transform: translate(-0%, -50%);
	transform: translate(-0%, -50%);
	background: white;
	border-bottom-right-radius: 90px;
	border-top-right-radius: 90px;
	box-shadow: 1px 1px 2px -2px rgba(0,0,0,0.12), 3px 1px 2px -2px rgba(0,0,0,0.24);
	margin-left: 139px;
	/*margin-left: 143px;*/ /*Sticky Stations Toggle Switch*/
	cursor: pointer;
	z-index: 2;
}

/*Circle caret*/
#circleCaret {
	width: 13px;
    height: 13px;
    position: absolute;
    top: 42%;
    left: 0%;
    -webkit-transform: translate(-0%, -40%);
    transform: translate(-0%, -40%);
    border-top: 3px black solid;
    border-left: 3px black solid;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    transform: rotate(135deg);
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    margin-left: 9px;
}

#circleCaret.open {
	-webkit-transform: rotate(-45deg);
	-moz-transform: rotate(-45deg);
	-o-transform: rotate(-45deg);
	-ms-transform: rotate(-45deg);
	transform: rotate(-45deg);
	margin-left: 11px;
}


/* Links
* --------------------------------------- */
#left-side-tab-menu a:link {
	text-decoration: none;
	color: #000;
}
#left-side-tab-menu a:visited {
	text-decoration: none;
	color: #000;
}
#left-side-tab-menu a:hover {
	text-decoration: none;
	color: #000;
}
#left-side-tab-menu a:active {
	text-decoration: none;
	color: #000;
}