/*Source: https://codepen.io/AllThingsSmitty/pen/MyqmdM*/

#chart-content {
	display:block;
	position: relative;
	width: 80%;
	max-width: 900px;
	min-width: 600px; /*set to match width of round out tabs via chart-content tab element*/
	height: 60px; /*initial height when closed*/
	min-height: 460px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: -460px; /*affects size of chart showing at bottom when closed*/
	cursor: default;
	background-color:white;
	box-shadow: 0 -45px 28px 0px rgba(0,0,0,0.25),
	0px -25px 14px -5px rgba(0,0,0,0.22),
	2px 20px 14px -6px rgba(0,0,0,0.22),
	-2px 20px 14px -6px rgba(0,0,0,0.22);
	z-index: 12;
}
#chart-content #operatingInstructions{
	width: 90%;
	margin-left: auto;
	margin-right: auto;
}

#chart-content #operatingInstructions p.introText{
	font-family: "futura-pt",sans-serif;
	font-size: 1.1em;
	font-weight: normal;
	letter-spacing: normal;
	padding-top: 30px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
}

/*Source: https://css-tricks.com/left-and-right/*/
.left-half {
	background-color: #fff;
	float: left;
	width: 70%;
}
.right-half {
	background-color: #fff;
	float: left;
	width: 30%;
}

#chart-content #operatingInstructions .imageColumn {
	width:100%;
	padding-top: 32px; /*match height between line and top of station chart*/
	padding-bottom: 100px; /*create space for closed captions*/
}
#chart-content #operatingInstructions .imageColumn img{
	display:block;
	width: 100%;
	max-width: 550px;
	height: auto;
}

#chart-content #operatingInstructions .listColumn{
	padding-top: 32px; /*match height between line and top of station chart*/
	padding-bottom: 460px; /*create space for closed captions*/
}
#chart-content #operatingInstructions .listColumn img{
	width: 25px;
	height: auto;
	float: left;
}
#chart-content #operatingInstructions .listColumn ul{
	list-style-type: none;
	padding-right: 15px;
}
#chart-content #operatingInstructions .listColumn ul li{
	padding-left:30px;
	padding-bottom:20px;
	float: left;
	width:210px;
}
#chart-content #operatingInstructions .listColumn ul li.lastListItem{
	padding-bottom:0px;
}
#chart-content #operatingInstructions .listColumn p{
	font-family: "futura-pt",sans-serif;
	font-size: 1em;
	font-weight: 500;
	letter-spacing: normal;
	padding-left:33px;
	padding-right:10px;
}

#chart-content #operatingInstructions .listColumn ul.portrait{
	display: none;
}

@media (max-width:950px) and (min-height: 723px){
	.left-half {
		background-color: #fff;
		clear: right;
		width: 100%;
	}
	.right-half {
		background-color: #fff;
		width: 100%;
	}
	#chart-content #operatingInstructions .imageColumn {
		padding-bottom: 0px;
	}
	#chart-content #operatingInstructions .imageColumn img{
		display:block;
		width: 80%;
		max-width: 500px;
		height: auto;
		margin-left:auto;
		margin-right:auto;
	}
	#chart-content #operatingInstructions .listColumn{
		display:block;
		width: 80%;
		max-width: 500px;
		min-width: 440px;
		height: auto;
		margin-left:auto;
		margin-right:auto;
		padding-top: 25px;
		padding-left: 25px;
		padding-bottom: 70px;
	}
	#chart-content #operatingInstructions .listColumn ul{
		padding-right: 0px;
	}
	#chart-content #operatingInstructions .listColumn ul li{
		display: inline-block;
		padding-left:0px;
		float: none;
		vertical-align: top;
	}
	#chart-content #operatingInstructions .listColumn ul li.rightRow{
		float: right;
		padding-right: 15px;
	}
	#chart-content #operatingInstructions .listColumn ul.portrait{
		display: block;
	}
	#chart-content #operatingInstructions .listColumn ul.landscape{
		display: none;
	}
}

@media (max-width:950px) and (max-height: 722px){
	#chart-content #operatingInstructions .listColumn img{
		width: 20px;
		height: auto;
		float: left;
	}
	#chart-content #operatingInstructions .listColumn ul li{
		padding-left:20px;
		padding-bottom:10px;
		float: left;
		width:180px;
	}
	#chart-content #operatingInstructions .listColumn p{
		font-family: "futura-pt",sans-serif;
		font-size: 0.9em;
		font-weight: 500;
		letter-spacing: normal;
		padding-left:28px;
		padding-right:20px;
	}
}

#stationChart {
	padding-bottom: 70px;
	font-family: "futura-pt",sans-serif;
	line-height: 1.25;
	display:none;
}

#stationChart h1{
	font-size: 1.25em;
	font-weight: 700;
	letter-spacing: normal;
	text-transform: uppercase;
}
#stationChart p.introText{
	font-family: "futura-pt",sans-serif;
	font-size: 1.1em;
	font-weight: normal;
	letter-spacing: normal;
	padding-top: 30px;
	width: 90%;
	margin-left: auto;
	margin-right: auto;
}
#stationChart table tbody {
	font-size: 1em;
	font-weight: 500;
	letter-spacing: normal;
}

#stationChart table {
	border-collapse: collapse;
	margin: 0;
	padding: 0;
	width: 90%;
	margin-left: 5%;
	table-layout: auto;
}
#stationChart table caption {
	font-size: 1.5em;
	padding: .75em 0 .5em 0;
	text-transform: uppercase;
	font-weight: 700;
	border-bottom: 2px solid #000;
}
#stationChart table tr {
	padding: .35em;
}

#stationChart table tr.odd {
	background: #F2F2F2;
}

#stationChart table th,
#stationChart table td {
	text-align: left;
}
#stationChart table th {
	padding: .625em 2em 0em .4em;
	font-size: .85em;
	letter-spacing: .1em;
	text-transform: uppercase;
}
#stationChart table td {
	padding: .4em;
}

/*Red light*/
.redLightStationChart:before {
	content: " ";
	display: inline-block;
	height: 8px;
	width: 8px;
	margin-left: 6px;
	background: #F3413E;
	border-radius: 50%;
	/*box-shadow: 0 0 10px 3px #F3413E;*/
}
.redLightStationChart {
	opacity: .9;
}
