#right-side-tab-omnitone{
	width: 136px;
	height: auto;
	position: absolute;
	top: 50%;
	right: 0%;
	-webkit-transform: translate(-0%, -50%);
	transform: translate(-0%, -50%);
	/*margin-right: -139px;*/ /*element width + box-shadow*/
	z-index: 100;
}
#right-side-tab-omnitone p{
	font-family: "futura-pt",sans-serif;
	/*font-family: Futura, "Trebuchet MS", Arial, sans-serif;*/
	font-weight: 400;
	font-size:15px;
	text-align: center;
	line-height: normal;
	letter-spacing: normal;
	color: #000;
	padding-bottom:7px;
}

#omnitone-controls{
	width: auto;
	height: auto;
	padding:10px;
	background-color: white;
	border-radius: 10px 0px 0px 10px;
	/*Source: https://codepen.io/sdthornton/pen/wBZdXq*/
	box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
	/*transition: all 0.3s cubic-bezier(.25,.8,.25,1);*/
	z-index: 100;
}
#omnitone-controls canvas{
	cursor: -webkit-grab;
	cursor: -moz-grab;
	cursor: -o-grab;
	cursor: grab;
	margin-left: 8px;
	margin-bottom: -3px;
}
#omnitone-controls canvas:active {
	cursor: -webkit-grabbing;
	cursor: -moz-grabbing;
	cursor: -o-grabbing;
	cursor: grabbing;
}
#omnitone-controls button {
    width: auto;
    height: auto;
    padding: 3px;
    font-family: "futura-pt",sans-serif;
	/*font-family: Futura, "Trebuchet MS", Arial, sans-serif;*/
	font-weight: 400;
	font-size:13px;
	text-align: center;
	line-height: normal;
	letter-spacing: normal;
	color: #000;
}
#omnitone-controls button#eButtonPlayback {
    margin-top: 10px;
}
#omnitone-controls dt {
    padding: 2px 0 2px 0;
}
#omnitone-controls dd {
    margin-left: 0;
    padding: 2px 0 2px 0;
}
#omnitone-controls dd>input[type=range] {
    width: 100%;
}

/*Hide select controls*/
#omnitone-controls .hideControls{
	display: none;
}

/*Hide select buttons*/
#omnitone-controls .hideButton{
	display: none;
}

/*Disable keyboard input*/
#omnitone-controls .knob{
	pointer-events: none;
}