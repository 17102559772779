/*Audio info*/
/*Audio description under marquee*/
#audio-info{
	width: auto;
	height: auto;
	font-family: "futura-pt",sans-serif;
	/*font-family: Futura, "Trebuchet MS", Arial, sans-serif;*/
	font-size:15px;
	font-weight: 300;
	text-align: center;
	line-height: 1.4;
	color: #FFF;
	margin-top: 39px;
	overflow: hidden;
	z-index: 22;
	/*opacity:0;*/
}

#audio-info.nearbyStationPosition {
	-webkit-animation: flicker 1.5s infinite;
	animation: flicker 1.5s infinite;
}

/*flicker defined in style_radio-tuner-marquee.css*/