/*Closed captions icon*/
#closedCaptionsIcon{
	width: auto;
	height: 58px;
	position: fixed;
	bottom: 2px;
	right: 2px;
	cursor: pointer;
	-webkit-transition: all 300ms;
    transition: all 300ms;
	z-index: 999;
}
#closedCaptionsIcon img{
	display: block;
	position: relative;
	width: auto;
	height: 100%;
	padding: 0px;
	margin: 0 auto !important;
}

/*Disable Closed captions icon animation on window resize if vertical scrollbar is detected*/
/*Source: https://stackoverflow.com/questions/11131875/what-is-the-cleanest-way-to-disable-css-transition-effects-temporarily*/
.notransition {
	-webkit-transition: none !important;
	-moz-transition: none !important;
	-o-transition: none !important;
	transition: none !important;
}