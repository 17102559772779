/*Coverlay*/
#coverlay{
}

#overlayCoverlay {
	background-color: #000;
	position: fixed;
	width: 100%; 
	height: 100%;
	z-index: 996;
	/*opacity: 0.85;*/
	opacity: 0;
	display:none;
}

/*Close button*/
#closeCoverlayButton{
	width: 100px;
	height: auto;
	position: fixed;
	top: 2px;
	right: 2px;
	cursor: pointer;
	z-index: 1001;
}
#closeCoverlayButton img{
	width: 100%;
	height: auto;
	display: block;
	position: relative;
	padding: 0px;
	margin: 0 auto !important;
}

#wrapCoverlay {
	position: fixed;
	width: 100%; 
	height: 100%;
	overflow-x: hidden;
	overflow-y: auto;
	z-index: 997;
	opacity: 0;
	display:none;
}

#coverlayContent {
	position: absolute;
	width: 80%;
	max-width: 900px;
	height: auto;
	top: 0%;
	left: 50%;
	-webkit-transform: translate(-50%, -0%);
	transform: translate(-50%, -0%);
	margin-top:50px;
	padding-bottom:80px;
	padding-top: 0px;
}
#coverlayContent.center {
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	margin-top:0px;
	padding-top: 50px;
}

#coverlayContent h1{
	/*max-width: 850px;*/
	font-family: "futura-pt",sans-serif;
	/*font-family: Futura, "Trebuchet MS", Arial, sans-serif;*/
	font-size:45px;
	font-weight:400;
	letter-spacing: 4px;
	line-height: normal;
	text-transform: uppercase;
	text-align: center;
	color: #FFF;
	margin-bottom: 10px;
}
#coverlayContent h2{
	font-family: "futura-pt",sans-serif;
	/*font-family: Futura, "Trebuchet MS", Arial, sans-serif;*/
	font-size:28px;
	font-weight:400;
	letter-spacing: 1px;
	line-height: normal;
	text-align: center;
	color: #FFF;
	padding-bottom:30px;
}
#coverlayContent p{
	/*max-width: 800px;
	margin-left: auto;
	margin-right: auto;*/
	font-family: "merriweather",serif;
	/*font-family: 'Merriweather', serif;*/
	font-size:15px;
	font-weight:400;
	line-height: 180%;
	text-align: left;
	color: #FFF;
}

#coverlayContent #moreMediaMessage p{
	font-size:13px;
	padding-top: 30px;
	text-align: center;
}

@media only screen and (max-width: 950px) {
	#coverlayContent h1{
		font-size:35px;
	}
	#coverlayContent h2{
		font-size:25px;
	}
}

.wrapEnterButton {
	text-align: center;
	padding-top: 35px;
}
.enterButton {
	position: relative;
	display: inline-block;
	border: 1px solid white;
	font: 400 22px "futura-pt",sans-serif;
	/*font: 400 22px Futura, "Trebuchet MS", Arial, sans-serif;*/
	text-align: center;
	text-transform: uppercase;
	color: #FFF;
	cursor: pointer;
	padding: 10px 23px;
	-webkit-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.enterButton:hover{
	color:#CCC;
}

/* Detect scroll bar width
* --------------------------------------- */
/*Source: https://davidwalsh.name/detect-scrollbar-width*/
.scrollbar-measure {
	width: 100px;
	height: 100px;
	overflow: scroll;
	position: absolute;
	top: -9999px;
}

/* Misc
* --------------------------------------- */
.avoid-clicks {
	pointer-events: none;
}

/* Links
* --------------------------------------- */

#coverlay a:link {
	text-decoration: underline;
	color: #FFF;
}
#coverlay a:visited {
	text-decoration: underline;
	color: #FFF;
}
#coverlay a:hover {
	text-decoration: underline;
	color: #CCC;
}
#coverlay a:active {
	text-decoration: underline;
	color: #CCC;
}