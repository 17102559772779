#marquee {
	width: 390px;
	height: 26px;
	position: absolute;
	margin-left: 30px;
	margin-top: 26px;
	font-family: "futura-pt",sans-serif;
	font-size:20px;
	font-weight: normal;
	text-align: center;
	/*line-height: 1.5;*/
	/*color: #e6e6e6;*/
	color: #FFF;
	cursor: default;
	overflow: hidden;
	z-index: 21;
}
#marquee.onStationPosition {
	margin-top: 15px;
}

/* Center marquee text if short */
/*.js-marquee-wrapper {
    position: relative;
    left: -100%;
}*/

#marquee.nearbyStationPosition {
	margin-top: 15px;
	-webkit-animation: flicker 1.5s infinite;
	animation: flicker 1.5s infinite;
}

/*Source: https://codepen.io/benjystanton/pen/wFIEe*/
@-webkit-keyframes flicker {
	0% {opacity:0;}
	9% {opacity:0;}
	10% {opacity:.5;}
	13% {opacity:0;}
	20% {opacity:.5;}
	25% {opacity:.2;}

	34% {opacity:0;}
	35% {opacity:.5;}
	38% {opacity:0;}
	45% {opacity:.5;}
	50% {opacity:.2;}

	59% {opacity:0;}
	64% {opacity:0;}
	73% {opacity:0.5;}
	81% {opacity:0;}
	85% {opacity:0.5;}
	100% {opacity:.2;}
}
@keyframes flicker {
	0% {opacity:0;}
	9% {opacity:0;}
	10% {opacity:.5;}
	13% {opacity:0;}
	20% {opacity:.5;}
	25% {opacity:.2;}

	34% {opacity:0;}
	35% {opacity:.5;}
	38% {opacity:0;}
	45% {opacity:.5;}
	50% {opacity:.2;}

	59% {opacity:0;}
	64% {opacity:0;}
	73% {opacity:0.5;}
	81% {opacity:0;}
	85% {opacity:0.5;}
	100% {opacity:.2;}
}