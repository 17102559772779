/*Source: https://css-tricks.com/couple-takes-sticky-footer/*/

/*Needed for correct chart positioning*/
#chart{
	height:100%;
	/*display: none;*/
	opacity: 0;
}
/*Sticky footer chart*/
#chart-child {
	display: flex;
	flex-direction: column;
	/*height: 100vh;*/
	/*height: 100%;*/
	/*min-height: 100vh;*/
	min-height: 100%;
}
/*Margin for sticky footer positionin*/
#chart-push {
	/*flex: 1;*/
	flex: 1 0 auto;
	margin-bottom: 552px; /*affects size of top margin before chart stops*/
}
/*Invisible overlay for closing chart on click*/
#chart-overlay {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0px;
	z-index: 10;
	display: none;
}