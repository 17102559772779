#knobBackground{
	position: absolute;
	top: -3%;
	left: 50%;
	-webkit-transform: translate(-50%, 3%);
	transform: translate(-50%, 3%);
	/*-webkit-transition: all 1s;
    transition: all 1s;*/
	z-index: -10;
}
#knobBackground img{
	display: block;
	width: 300px;
	/*-webkit-transition: all 1s;
    transition: all 1s;*/
}

#knobRotate{
	position: absolute;
	top: -5%;
	left: 50%;
	-webkit-transform: translate(-50%, 5%);
	transform: translate(-50%, 5%);
	/*-webkit-transition: all 1s;
    transition: all 1s;*/
	z-index: -10;
}
#knobRotate img{
	display: block;
	width: 300px;
	opacity: 0.30;
	/*-webkit-transition: all 1s;
    transition: all 1s;*/
}

#knobRotateNotches{
	position: absolute;
	top: -5%;
	left: 50%;
	-webkit-transform: translate(-50%, 5%);
	transform: translate(-50%, 5%);
	/*-webkit-transition: all 1s;
    transition: all 1s;*/
	z-index: -10;
}
#knobRotateNotches img{
	display: block;
	width: 300px;
	opacity: 0.2;
	/*-webkit-transition: all 1s;
    transition: all 1s;*/
}