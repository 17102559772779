/*Notches*/
#frequencies {
	position: absolute;
	top: -44px; /*mod*/
	left: -26px;
	width: 480px;
	text-align:left;
}
#frequencies > div {
	display: block;
	float: left;
	color: rgba(255, 255, 255, 1);
	font-size: 32px;
	letter-spacing: 2px;
	font-family: 'Wire One', sans-serif;
	font-weight: 600;
	text-align: center;
	width:56px; /*width between numbers*/
}
#progressLong {
	position: absolute;
	top:114px; /*mod*/
	left:28px; /*mod*/
	width:400px; /*mod*/
	opacity: 1; /*mod*/
	height: 24px; /*height of long frequency marks*/
	background-image:
	linear-gradient(to right, rgba(255,255,255,1) 2px, transparent 2px);
	background-size:28px 100px, 14px 10px; /*first pixel value affects number of small noches; forth pixel value affects height of small noches; first and third pixel values affect distance between long noches, must be multiples of eachother*/
	background-position:0 bottom;
	background-repeat: repeat-x;
}
#progressShort {
	position: absolute;
	top:126px;
	left:42px;
	width:380px;
	opacity: 1;
	height: 12px; /*height of short frequency marks*/
	background-image:
	linear-gradient(to right, rgba(255,255,255,1) 2px, transparent 2px);
	background-size:28px 100px, 14px 10px; /*first pixel value affects number of small noches; forth pixel value affects height of small noches; first and third pixel values affect distance between long noches, must be multiples of eachother*/
	background-position:0 bottom;
	background-repeat: repeat-x;
}
#progressBottomLine {
	position: absolute;
	bottom:20px; /*mod*/
	left:0px; /*mod*/
	width:450px; /*mod*/
	opacity: 1; /*mod*/
	border-bottom: 2px solid white;
}
#pointer {
	position:absolute;
	top:-54px; /*mod*/
	left:0px;
	width:4px;
	height: 102px; /*mod*/
	background: #F3413E;
	border-radius: 10px; /*mod*/
	/*box-shadow: inset 0 1px 0 0px rgba(255,255,255,1), 1px 2px 3px 0 rgba(0,0,0,0);*/ /*mod*/
	z-index: 22;
}