/*Enter page*/
#enter-page{
	/*display: none;*/
	visibility: hidden;
	opacity: 0;
}
#enter-page.visible{
	visibility: visible;
}

/*Content*/
#enterPageContent {
	position: absolute;
	/*width: 80%;
	max-width: 600px;*/
	width: 450px;
	height: 530px;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	z-index: 99999;
}

/*Media Queries*/
@media screen and (max-height: 530px) {
	#enterPageContent {
		top: 0%;
		left: 50%;
		-webkit-transform: translate(-50%, -0%);
		transform: translate(-50%, -0%);
	}
}

/*Logo*/
#enterPageLogo{
	width: 350px;
	height: auto;
	display: block;
	position: absolute;
	top: 0%;
	left: 50%;
	-webkit-transform: translate(-50%, 0%);
	transform: translate(-50%, 0%);
}
#enterPageLogo img{
	display: block;
	position: relative;
	width: 100%;
	height: auto;
}

/*Message*/
#enterPageMessage{
	width: 100%;
	height: auto;
	position: absolute;
	top: 55%;
	left: 50%;
	-webkit-transform: translate(-50%, 55%);
	transform: translate(-50%, 55%);
}
#enterPageMessage h1{
	font-family: "futura-pt",sans-serif;
	/*font-family: Futura, "Trebuchet MS", Arial, sans-serif;*/
	font-weight: 500;
	font-size:20px;
	line-height: 120%;
	letter-spacing: normal;
	color: #000;
	text-align: center;
}
#enterPageMessage h2{
	font-family: "futura-pt",sans-serif;
	/*font-family: Futura, "Trebuchet MS", Arial, sans-serif;*/
	font-weight: 700;
	font-size:15px;
	line-height: 120%;
	letter-spacing: normal;
	color: #000;
	text-align: center;
	text-transform: uppercase;
	padding-top:3px;
}
#enterPageMessage h3{
	font-family: "futura-pt",sans-serif;
	/*font-family: Futura, "Trebuchet MS", Arial, sans-serif;*/
	font-weight: 500;
	font-size:14px;
	line-height: 120%;
	letter-spacing: normal;
	color: #000;
	text-align: center;
	padding-top:22px;
}

/*Enter button*/
#enterPageWrapEnterButton {
	/*width: 150px;*/
	/*height: auto;*/
	display: block;
	position: absolute;
	bottom: 0%;
	left: 50%;
	-webkit-transform: translate(-50%, 0%);
	transform: translate(-50%, 0%);
	text-align: center;
	/*padding-top: 35px;*/
}
#enterPageEnterButton {
	position: relative;
	display: inline-block;
	border: 1px solid black;
	font: 400 22px "futura-pt",sans-serif;
	/*font: 400 22px Futura, "Trebuchet MS", Arial, sans-serif;*/
	text-align: center;
	text-transform: uppercase;
	color: #000;
	cursor: pointer;
	padding: 10px 23px;
	-webkit-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
#enterPageEnterButton:hover{
	color:#666;
}


