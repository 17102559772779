/*Remote status*/
/*For quarter cicle background*/
/*#remote-status{
	position: fixed;
	top: 0px;
	right: 0px;
	width: 200px;
	height: 200px;
	background: white;
	border-radius: 0 0 0 200px;
	-moz-border-radius: 0 0 0 200px;
	-webkit-border-radius: 0 0 0 200px;
	box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
	cursor: pointer;
	z-index: 11;
}*/
#remote-status{
}

#remote-status.remotePaired{
	cursor: default;
}

#remote-status img{
	display: block;
	position: relative;
	width: 100%;
	height: auto;
}

#remote-status p{
	font-family: "futura-pt",sans-serif;
	/*font-family: Futura, "Trebuchet MS", Arial, sans-serif;*/
	font-weight: 500;
	font-size:13px;
	text-align: center;
	text-transform: uppercase;
	line-height: 120%;
	letter-spacing: normal;
	color: #000;
	margin-top: 12px;
}

/*Remote status not paired*/
#remote-status-not-paired{
	width: 130px;
	height: auto;
	position: fixed;
	top: 20px;
	right: 20px;
	z-index: 11;
	cursor: pointer;
	display: none;
}

#remote-status-not-paired img{
	max-width: 100px;
	margin-left: 23px;
}

#remote-status-not-paired p{
	margin-left: 9px;
}

/*Remote status paired*/
#remote-status-paired{
	width: 100px;
	height: auto;
	position: fixed;
	top: 15px;
	right: 25px;
	z-index: 11;
	display: none;
}

