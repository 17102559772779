/*@import url(//fonts.googleapis.com/css?family=Lobster|Limelight|Wire+One);*/

/* CSS Reset
* --------------------------------------- */
* {
	-webkit-user-select: none; /* Safari 3.1+ */
	-moz-user-select: none; /* Firefox 2+ */
	-ms-user-select: none; /* IE 10+ */
	user-select: none; /* Standard syntax */
	outline: 0 !important;
	margin: 0px;
	padding: 0px;
}

/* Body and HTML
* --------------------------------------- */
html {
	height:100%;
	overflow: hidden;
}
body{
	width: 100%;
	height:100%;
	background: linear-gradient(to bottom, rgba(249,249,249,1) 0%, rgba(213,213,213,1) 100%);
	background-attachment: fixed; /*Fixes background color when resizing*/
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow: hidden;
}

/* Misc
* --------------------------------------- */
/*.avoid-clicks {
	pointer-events: none;
}*/