/*Video for closed captions*/
video {
	display:none;
}

/*Caption styling*/
#closed-captions {
	background-color:black;
	width: 100%;
	height:60px;
	position: fixed;
	bottom: 0%;
	left: 50%;
	-webkit-transform: translate(-50%, 0%);
	transform: translate(-50%, 0%);
	z-index:998;
	display:none;
}
#closed-captions .caption {
	width: 500px;
	height:40px;
	position: absolute;
	bottom: 0%;
	left: 50%;
	-webkit-transform: translate(-50%, 0%);
	transform: translate(-50%, 0%);
	margin-bottom:10px;
	opacity:0;
}
#closed-captions #errorMessage {
	width: 400px;
	height:40px;
	position: absolute;
	bottom: 0%;
	left: 50%;
	-webkit-transform: translate(-50%, 0%);
	transform: translate(-50%, 0%);
	margin-bottom:10px;
	display: none;
}
#closed-captions #captionStatic{
	margin-bottom:0px;
}

/* Text
* --------------------------------------- */
p.caption{
	font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
	font-size: 18px;
	font-style: normal;
	font-variant: normal;
	font-weight: 400;
	line-height: 20px;
	color:#fff;
	text-align: center;
}
/*p.caption span { 
    background-color: black;
	padding:5px;
}
*/
p#errorMessage{
	font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
	font-size: 18px;
	font-style: normal;
	font-variant: normal;
	font-weight: 400;
	line-height: 20px;
	color:#f00;
	text-align: center;
}
p#errorMessage span { 
    background-color: black; 
}

/* Links
* --------------------------------------- */
#errorMessage a:link {
	text-decoration: underline;
	color: #f00;
}
#errorMessage a:visited {
	text-decoration: underline;
	color: #f00;
}
#errorMessage a:hover {
	text-decoration: underline;
	color: #f00;
}
#errorMessage a:active {
	text-decoration: underline;
	color: #f00;
}